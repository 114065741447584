.Notification {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  margin: 25px;
  padding: 10px 15px 10px 15px;
  font-size: 20px;
  border-radius: 5px;
  color: var(--white);
  background-color: #656565;
  animation-name: show;
  animation-duration: 1s;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin: 0 5px 2px 0;
    align-self: center;
    fill: #16f516;
    // animation-name: blinker;
    // animation-iteration-count: infinite;
    // animation-duration: 1s;
  }
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
