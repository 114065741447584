.SalesTypes {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
    margin-bottom: 10px;
  }
  .ControlRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  
    .ClubSelect{
      width: 400px;
    }
  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Archive {
    svg {
      width: 25px;
      height: 25px;
      fill: var(--blue);
      &:hover {
        cursor: pointer;
        fill: var(--red);
      }
    }
  }

  .Edit {
    svg {
      width: 25px;
      height: 25px;
      fill: var(--blue);
      &:hover {
        cursor: pointer;
        fill: var(--aqua);
      }
    }
  }

.AddMode{
  width: 400px;
  margin: 15px;
  .title{
    font-weight: bold;
    margin-top: 15px;

  }
  .AddModeControlRow{
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
}

  .TypesTabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    .TypesTab {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      width: 250px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #46494d;
    }
    @media only screen and (max-device-width: 640px) {
      .authTab {
        width: 100%;
      }
    }
    .active {
      border-bottom: 1px solid var(--basedim);
      color: var(--blue);
    }
  }
}
