.EditClient {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
   
    
    .NewClientInput {
        width: 100%;
      margin: 10px 0 0 0;
      input {
        font-size: 22px;
      }
    }
   
    .NewClientClub{
        width: 100%;
      margin: 10px 0 0 0;
    }
    .NewClientAge {
        width: 100%;
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      text-align: center;
      .NewClientAgeTitle {
        margin: 0 10px 0 0;
        align-self: center;
      }
    }
    .EditClientControlRow {
      margin: 10px 0 0 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  