* {
  box-sizing: border-box;
}
body {
  font-family: SF Pro Display, sans-serif;
  background-color: #f3f4f8;
}
body,
html {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

:root {
  --white: #fff;
  --font: #333;
  --base: #f7f4f3;
  --basedim: #d7d4d3;
  --basetransparent: #d7d4d344;
  --grey: #9b9b9b88;
  --accent: #13293d;
  // --aqua: #16bac5;
  // --blue: #1b98e0;
  --aqua: #84aea3;
  --blue: #4b9495;
  --dimblue: #006494;
  --warning: #ec8338;
  --text: #0a2540;
  --red: #fa8484;
  --green: #68c06e;
}
.GlobalHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

.GlobalError{
  margin: 10px;
  font-weight: bold;
  color: #fa8484;
}

.GlobalTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 3px solid var(--basedim);
  margin: 0 2.5px 0 2.5px;
  .tab {
    box-shadow: 0px 0px 8px 0px var(--basetransparent);
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #46494d;
    margin: 15px 7.5px 0 7.5px;
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    opacity: 0.6;
    background-color: #fdfdfd;
    transition: opacity 0.5s, background-color 0.5s;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      color: var(--blue);
      opacity: 1;
      background-color: var(--white);
    }
  }
  .active {
    opacity: 1;
    background-color: var(--white);
    border: 1px solid var(--basedim);
    border-bottom: none;
  }
}

.GlobalEditButton{
  font-weight: bold;
  padding: 2px 10px 2px 10px;
  margin: 0 0 5px 10px;
  color: #fff;
  border-radius: 7px;
  background-color: var(--blue);
  &:hover {
    cursor: pointer;
    background-color: var(--aqua);
  }
}