.Preloader {
  margin: 50px;
  width: 100%;
  $color: #f0b55a, #f1ab42, #f1a22a, #f0845a, #f07d5a;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 10vh;
    overflow: hidden;
  }

  $circle-size: 20px;
  $circle-margin: 7px;

  .circle {
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    margin: $circle-margin;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      width: $circle-size;
      height: $circle-size;
      border-radius: 50%;
      opacity: 0.7;
      animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    }
  }

  @for $i from 1 through 5 {
    .circle-#{$i} {
      background-color: nth($color, $i);
      &:before {
        background-color: nth($color, $i);
        animation-delay: 200ms * $i;
      }
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
    75% {
      transform: scale(2.5);
    }
    78%,
    100% {
      opacity: 0;
    }
  }
}
