.ClientInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  .ControlRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .VisitsRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px;
    padding: 10px;
    border: 1px solid var(--basedim);
    box-shadow: 2px 2px 8px 1px var(--grey);
    border-radius: 7px;
    .VisitIndicator{
      margin: 5px 5px 0 0;
      display: flex;
      flex-direction: row;
    }
    .VisitsRowDelimiter{
      display: flex;
      flex: 1;
    }
    .CanEnterIndicator{
      color: #eb4034;
      font-weight: bold;
      display: flex;
      text-align: center;
      align-items: center;
    }
    .EnterIndicator{
      // color: #58d328;
      font-weight: bold;
      display: flex;
      text-align: center;
      align-items: center;
      margin-left: 10px;
      margin: 0 5px 0 0;
    }
    .today{
      color: #58d328;
    }
    .ExitIndicator{
      color: #eb4034;
      font-weight: bold;
      display: flex;
      text-align: center;
      align-items: center;
   
    }
  }
  .Header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
  }
  .Edit {
    font-weight: bold;
    padding: 2px 10px 2px 10px;
    margin: 0 0 5px 10px;
    color: #fff;
    border-radius: 7px;
    background-color: var(--blue);
    &:hover {
      cursor: pointer;
      background-color: var(--aqua);
    }
  }
  .Pass {
    font-weight: bold;
    padding: 2px 10px 2px 10px;
    margin: 0 0 5px 10px;
    color: #fff;
    border-radius: 7px;
    background-color: var(--blue);
  }
  .Client {
    .ClientContainer {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin: 10px;
      padding: 10px;
      border: 1px solid var(--basedim);
      box-shadow: 2px 2px 8px 1px var(--grey);
      border-radius: 7px;
      .ClientPhoto {
        height: 230px;
        position: relative;
        img {
          cursor: pointer;
          height: 230px;
          width: 230px;
          display: block;
        }
        .NoImage {
          height: 230px;
          width: 230px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--basedim);
          border-radius: 7px;
          color: var(--basedim);
          font-weight: bold;
          svg {
            fill: var(--basedim);
          }
          &:hover {
            cursor: pointer;
            color: var(--aqua);
            svg {
              fill: var(--aqua);
            }
          }
        }
      }
      .ClientData {
        margin: 0 10px 0 10px;
        width: 100%;
        .ClientDataRow {
          margin: 0 0 5px 0;
          display: flex;
          flex: 1;
          flex-direction: row;
          border-bottom: 1px solid var(--basedim);
          .title {
            width: 100%;
            font-weight: bold;
            min-width: 200px;
          }
          .value {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          
          .Cake {
            svg {
              animation-name: blinker;
              animation-iteration-count: infinite;
              animation-duration: 0.3s;
              width: 25px;
              height: 25px;
              fill: rgb(243, 34, 243);
            }
            @keyframes blinker {
              from {
                opacity: 1;
              }
              to {
                opacity: 0;
              }
            }
          }
        }
        .ClientInfoRow{
          .title {
            width: 100%;
            font-weight: bold;
            min-width: 200px;
          }
          .info{
            font-size: 18px;
            font-style: italic;
            margin: 5px 0 5px 0;
            padding: 5px;
            border: 1px solid #cecece;
            border-radius: 5px;
            min-height: 50px;
            background-color: #4b949513;
          }
        }
      }
    }
  }

  .loading {
    margin: 15px;
    font-weight: bold;
  }
}
