.ModalWrapperLk {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(68, 68, 68, 0.453);
  background-color: #2b2a29;
  z-index: 9999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modalContentLk {
    z-index: 9999;
    width: 400px;
    overflow-y: scroll;
    padding: 5px;
    opacity: 1;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 2px 2px 5px gray;
    border: 1px solid rgba(128, 128, 128, 0.5);
  }
  @media only screen and (max-device-width: 640px) {
    .modalContentLk {
      z-index: 9999;
      width: 100%;
      
      overflow-y: scroll;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .gradient {
    --size: 250px;
    --speed: 10s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    position: absolute;
    z-index: 1;
    width: var(--size);
    height: var(--size);
    filter: blur(calc(var(--size) / 5));
    background-image: linear-gradient(
      #12e8f8d9,
      #ff6e1d
    );
    animation: rotate var(--speed) var(--easing) alternate infinite;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }

  @media (min-width: 720px) {
    .gradient {
      --size: 350px;
    }
  }
  @media only screen and (max-device-width: 640px) {
    .gradient {
      --size: 500px;
    }
  }
  * {
    transition: all 0.25s ease-out;
  }
  .modalContentLk::-webkit-scrollbar {
    width: 0px;
  }
  .modalContentLk::-webkit-scrollbar-thumb {
    background-color: #f0b55a;
    border-radius: 5px;
  }
  .modalContentLk::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
