.MainPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--font);
  .MainHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 75px;
    background-color: #fff;
    border-bottom: 15px solid var(--base);
    opacity: 0.3;
    .CurrentDateTest {
      display: flex;
      justify-self: center;
      text-align: center;
      align-items: center;
      color: #fff;
      margin: 0 15px 0 0;
      opacity: 1;
    }
    .NetworkError{
      display: flex;
      justify-self: center;
      text-align: center;
      align-items: center;
      color: #ff0202;
      font-weight: bold;
      opacity: 1;
     
    }
    .indicator {
      align-self: center;
      margin: 15px;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      border: 1px solid #b3b3b3;
      opacity: 1;
    }
    .on {
      background-color: #08ff15;
    }
    .off {
      background-color: #ff0202;
    }
  }
  .MainContent {
    display: flex;
    flex-direction: row;

    .MainMenu {
      background-color: #fff;
      box-shadow: 0px 4px 8px 0px var(--basedim);
      font-size: 20px;
      width: 200px;
      display: flex;
      height: 100%;
      flex-direction: column;
      a {
        border-bottom: 1px solid var(--basedim);
        border-right: 15px solid var(--base);
        text-align: end;
        padding: 10px 10px 10px 0;
        text-decoration: none;
        color: var(--font);
        &:hover {
          background-color: var(--base);
        }
      }
      .ActiveNavLink {
        border-right: 5px solid var(--blue);
        color: var(--blue);
        box-shadow: 0px 4px 8px 0px var(--basedim);
      }
      .MainMenuDelimiter {
        display: flex;
        flex: 1;
      }
      .CurrentUser {
        font-size: 16px;
        padding: 5px;
        font-style: italic;
      }
      .Version {
        font-size: 16px;
        color: #6d6d6d;
        font-style: italic;
      }
      .Exit {
        color: var(--blue);
        font-weight: bold;
        border-top: 1px solid var(--basedim);
        border-right: none;
        padding: 10px 20px 10px 0;
        svg {
          width: 15px;
          height: 15px;
          fill: var(--blue);
        }
      }
    }
    .MenuContent {
      display: flex;
      flex: 1;
      padding: 15px;
      border-radius: 7px;
      background-color: #fff;
      box-shadow: 0px 4px 8px 0px var(--basedim);
    }
  }
}
