button.button {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 35px;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  border: none;
  min-width: 200px;
  transition: background 0.5s ease-in-out;
  border-radius: 3px;
  background: var(--blue);
  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }
  position: relative;
  overflow: hidden;
  .expander {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--blue);
    border-radius: 50% 50%;
    animation: buttonExpander 0.5s ease-in;
  }
  .buttonContent {
    position: relative;
  }
  &:hover {
    background: var(--aqua);
  }
  &.inverted {
    background: var(--warning);
    &:hover {
      background: var(--basedim);
    }
    .expander {
      background: var(--warning);
    }
  }
}

@keyframes buttonExpander {
  from {
    height: 0;
    width: 0;
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    height: 300px;
    width: 300px;
    opacity: 0;
    transform: translate(-150px, -150px);
  }
}

.buttonConfirm {
 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  height: 35px;
  white-space: nowrap;
  min-width: 200px;
  .confirm {
    color: var(--white);
    transition: background 0.5s ease-in-out;
    border-radius: 3px;
    background: var(--blue);
    padding: 5px 20px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      background: var(--aqua);
    }
  }
  
}
