.ServiceScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
  }
  .ExportCard {
    width: 50%;
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px;
    margin: 10px 0 10px 0;
    .ExportFilter {
      border-bottom: 1px solid var(--basedim);
      padding: 0 0 10px 0;
      margin-bottom: 10px;
      .ExportFilterTitle {
        font-weight: bold;
        margin: 0 0 10px 0;
      }
      .ExportFilterRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .ExportFilterInput{
        margin: 0;
        width: 100%;
        display: flex;
        flex: 1;
      }
    }
    .ExportControl {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .ExportDownload{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0 10px 0;
    .ExportError{
      font-weight: bold;
      color: red;
      margin-right: 15px;
    }
  }
  .ExportClient{
    width: 400px;
    overflow-x: auto;
  }
}
