.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.253);
  z-index: 111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modalContent {
    padding: 5px;
    opacity: 1;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 0 10px var(--basedim);

    .modalTitle {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .titleText {
        font-weight: bold;
        margin: auto;
        text-align: center;
        padding: 0 15px 0 15px;
      }
    }
    .modalChildren {
    }
    .modalClose {
      fill: var(--blue);
      &:hover {
        cursor: pointer;
        fill: var(--aqua);
      }
    }
  }
  @media only screen and (max-device-width: 640px) {
    .modalContent {
      width: 100%;
      height: 90%;
      overflow-y: scroll;
    }
  }
}
