.Chart {
  width: 100%;
  height: 300px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  .BarContainer {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .Bar{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .BarValue{
            text-align: center;
            font-weight: bold;
        }
        .BarBand{
            width: 10px;
            align-self: center;
            background-color: green;
            border: 1px gray solid;
            max-height: 290px;
        }
        .BarLabel{
            text-align: center;
            font-weight: bold;
        }
    }
  }
}
