.selectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  .title {
    display: flex;
    align-items: center;
    min-width: 30%;
    margin: 0 10px 0 0;
  }
  select {
    font-size: 18px;
    width: 100%;
    user-select: none;
    border: 1px solid #ccc;
    padding: 8px 15px;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .isPayment{
    width: 200px;
  }
}
