.DictaphoneContainer {
  cursor: pointer;


  align-self: center;
  .listening {
    fill: var(--red);
    animation: micListening 0.8s ease-out infinite;
  }
  .default {
    fill: var(--blue);
  }
  @keyframes micListening {
    0% {
        fill: var(--blue);
    }
    50% {
        fill: var(--red);
    }
    100% {
        fill: var(--blue);
    }
  }
}
