.NewClient {
  .NewClientCard {
    width: 440px;
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px;
    margin: 10px 0 0 0;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
  }
  .NewClientWebcamPicker {
    width: 400px;
    margin: 5px 0 0 0;
  }
  .NewClientInput {
    width: 420px;
    margin: 10px 0 0 0;
    input {
      font-size: 22px;
    }
  }
  .NewClientNoCard {
    width: 420px;
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  .NewClientGender {
    width: 420px;
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .NewClientClub{
    width: 420px;
    margin: 10px 0 0 0;
  }
  .NewClientAge {
    width: 420px;
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: center;
    .NewClientAgeTitle {
      margin: 0 10px 0 0;
      align-self: center;
    }
  }
  .NewClientControlRow {
    margin: 10px 0 0 0;
    width: 420px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
