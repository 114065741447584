.ServiceScreen{
  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
    margin-bottom: 10px;
  }
    .Card {
        border: 1px solid var(--basedim);
        border-radius: 7px;
        padding: 10px;
        margin: 0 10px 10px 10px;
        width: 600px;
        .Title {
          font-weight: bold;
          border-bottom: 1px solid var(--basedim);
          margin-bottom: 10px;
        }
        .CardItem {
          align-items: center;
          display: flex;
          flex-direction: row;
          padding-bottom: 10px;
          b {
            text-decoration: underline;
            font-size: 20px;
            margin-left: 5px;
          }
        }
        .ServiceSelect {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 0 0 10px 0;
          .newClientAbonement {
            font-weight: bold;
          }
        }
        .saleSearch {
          margin: 0 0 10px 0;
          display: flex;
          flex-direction: row;
          flex: 1;
        }
        .CardItemSpace {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          b {
            padding: 15px 0 15px 0;
          }
        }
        .ControlRow {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }

      .BanUser {
        svg {
          fill: red;
          width: 25px;
          height: 25px;
          opacity: 0.4;
          &:hover {
            cursor: pointer;
            opacity: 1;
          }
        }
      }
      
}