.AuthScreen {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .authForm {
      width: 365px;
      border-radius: 5px;
      box-shadow: 0px 4px 8px 0px var(--basetransparent);
      background: var(--white);
      padding: 15px;
      margin: 15px;
      display: flex;
      flex-direction: column;
      .authNavigation {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        .authTab {
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: center;
          padding: 5px 15px 5px 15px;
          width: 250px;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #46494d;
        }
        @media only screen and (max-device-width: 640px) {
          .authTab {
            width: 100%;
          }
        }
        .active {
          border-bottom: 1px solid var(--basedim);
          color: var(--blue);
        }
      }
      .authFormContent {
        //height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .authCheckBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 5px 15px 0 0;
        color: #46494d;
        font-size: 12px;
        line-height: 18px;
      }
    }
    @media only screen and (max-device-width: 640px) {
      .authForm {
        width: 100%;
      }
    }
    input {
      padding: 10px;
      margin: 5px 20px 5px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      border: 1px solid var(--basedim);
      border-radius: 5px;
      outline: none;
    }
    .authError {
      color: #ff8686;
      margin: 10px 20px 10px 20px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }
  