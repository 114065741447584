.Abonement {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--basedim);
  border-radius: 7px;
  padding: 10px;
  margin: 10px 0 0 0;
  .AbonementHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 10px 0;
    .AbonementName {
      font-weight: bold;
    }
    .AbonementShowInfo {
      cursor: pointer;
      &:hover {
        svg {
          fill: var(--aqua);
        }
      }
      svg {
        width: 25px;
        height: 25px;
        fill: var(--blue);
      }
    }
    .AbonementTimeWarning {
      svg {
        width: 25px;
        height: 25px;
        fill: red;
      }
    }
  }
  .AbonementDates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--basedim);
    margin: 0 0 10px 0;
  }
  .InfoText {
    font-style: italic;
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 10px 0;
    .AbonementVisits{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  .AbonementData {
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px;
    p {
      border-bottom: 1px solid var(--basedim);
      margin: 0 0 10px 0;
    }
  }
  .AbonementEdit {
    border: 1px solid var(--basedim);
    border-radius: 7px;
    margin-top: 10px;
    .AbonementEditInput {
      margin: 0;
    }
    .AbonementEditTitle {
      border-bottom: 1px solid var(--basedim);
      font-weight: bold;
      padding: 5px 0 5px 5px;
    }
    .AbonementEditContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 10px 0 10px;
    }
  }
  .AbonementEditBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px;
  }
  .Status{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 10px 0;
    color: #f79c35;
  }
}
