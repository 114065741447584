.ActionScreen {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ActionScreenControl {
    width: 500px;
    margin: 0 0 10px 0;
    .ActionFilterRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      margin: 0 10px 10px 0;
      .ActionFilterItem {
       
        span {
          font-weight: bold;
        }
      }
    }
  }
}
