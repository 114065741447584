.preloaderShutter {
    z-index: 1;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 45px;
      width: 45px;
     
    }
    .carriage {
      animation-name: carriage-spin;
      animation-duration: 0.5s;
      //animation-timing-function: cubic-bezier(.59,.18,.48,.87);
      animation-iteration-count: infinite;
    }
  }
  
  @keyframes carriage-spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }
  