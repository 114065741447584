.checkBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    stroke: var(--blue);
    user-select: none;
    .checkBoxName {
      line-height: 14px;
      margin-top: 2px;
      margin-right: 5px;
    }
    .checkBoxBody {
      width: 20px;
      height: 20px;
      border: 1px solid var(--basedim);
      border-radius: 3px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: border-color 0.5s;
      outline: none;
      cursor: pointer;
      &:focus {
        border-color: var(--blue);
      }
    }
  }
  