.PersonalAccount {
  user-select: none;
  .LogoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .AuthContainer {
    height: 300px;
  }
  .Title {
    color: #25293d;
    font-weight: 500;
    font-size: 16px;
    margin: 0 15px 0 15px;
    border-bottom: 1px solid #f0b55a;
  }
  .AbonementsMenu {
    display: flex;
    flex-direction: row;
    margin: 0 10px 0 10px;
    div {
      margin: 5px 0 5px 5px;
      padding: 5px;
      color: #25293d;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;
      border: 1px solid #f0b55a;
      border-radius: 5px;
      &:hover {
        background-color: #fac97f;
        color: #fff;
      }
    }
    .active {
      background-color: #f0b55a;
      color: #fff;
    }
  }
  .AuthInfo {
    text-align: center;
    color: #25293d;
    margin: 0 15px 0 15px;
    font-size: 18px;
    font-family: 'Montserrat',Arial,sans-serif;
    line-height: NaN;
    font-weight: 300;
    padding: 0 15px 0 15px;
  }
  .ControlRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ShowPassword {
    margin: 0 0 15px 0;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
  .Error {
    font-weight: 500;
    color: #f04d4d;
    margin: 0 0 15px 0;
  }
  .ClientLogin {
    font-size: 15px;
    color: #25293d;
    font-weight: 600;
    margin: 15px;
  }
  .AbonementsContainer {
    margin: 0px 15px 15px 15px;
    .Expired{
      background-color: #fac4c4;
    }
    .Abonement {
      border: 1px solid #f0b55a;
      padding: 5px;
      margin: 0 0 10px 0;
  
      border-radius: 5px;
      color: #25293d;
      font-weight: 500;
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px;
        border-bottom: 1px solid #f0b55a;
      }
      .nonBorder {
        border: none;
      }
      .delimiter {
        margin-top: 30px;
      }
      .Confirm {
        .ConfirmTitle {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: center;
          margin: 30px 0 15px 0;
          font-size: 18px;
        }
        .ConfirmRow {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 0 0 30px 0;
          div {
            width: 35px;
            background-color: #f0b55a;
            text-align: center;
            padding: 5px;
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            cursor: pointer;
            border-radius: 5px;
            &:hover {
              background-color: #4bb9cc;
            }
          }
        }
      }
      .TimeName {
        font-size: 16px;
        background-color: #f0b55a;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
      }
      .NotActive {
        font-size: 16px;
        background-color: #f0b55a;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 15px;
      }
      .Freeze {
        font-size: 16px;
        background-color: #4bb9cc;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
      }
      .alreadyFreeze{
        text-align: center;
        font-size: 16px;
        background-color: #4bb9cc;
        color: #fff;
        border-radius: 5px;
        padding: 5px;
      }
      .FreezeAbon {
        width: 25px;
        background-color: #f0b55a;
        text-align: center;
        padding: 5px;
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          background-color: #4bb9cc;
        }
      }
    }
  }
  @media only screen and (max-device-width: 640px) {
    .AbonementsContainer {
      max-height: 100%;
    }
  }
}
body::-webkit-scrollbar {
  width: 1px;
}
body::-webkit-scrollbar-thumb {
  background-color: #f0b55a;
  border-radius: 5px;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
