.InputRow{

  display: flex;
  flex-direction: row;
  font-size: 18px;
  .Input {
    font-size: 18px;
    flex: 1;
    border: 1px solid var(--basedim);
    position: relative;
    padding: 15px 10px 2px;
    background: var(--white);
    border-radius: 5px;
    span {
      position: absolute;
      top: 2px;
      left: 10px;
      font-size: 12px;
      line-height: 13px;
      color: var(--font);
      font-size: 15px;
    }
    input {
      font-size: 18px;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      display: block;
      background: none;
    }
    textarea {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      width: 100%;
      height: 100px;
      border: none;
      outline: none;
      display: block;
      background: none;
    }
  }
  .InputDate{
    border: 1px solid var(--basedim);
    position: relative;
    padding: 5px;
    background: var(--white);
    border-radius: 5px;
    input {
      width: 100%;
      height: 30px;
      border: none;
      outline: none;
      display: block;
      background: none;
      font-size: 18px;
    }
  }
  
}

