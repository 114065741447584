.AbonementInfo {
  display: flex;
  flex: 1;
  flex-direction: row;
  .AbonementInfoBlock {
    display: flex;
    flex: 1;
    flex-direction: column;
    .showExpired {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .newSaleAmount {
      margin: 0 0 10px 0;
    }
    .Card {
      box-shadow: 2px 2px 8px 1px var(--grey);
      border: 1px solid var(--basedim);
      border-radius: 7px;
      padding: 10px;
      margin: 0 10px 10px 10px;
      &:hover {
        border: 1px solid var(--blue);
      }
      .Title {
        font-weight: bold;
        border-bottom: 1px solid var(--basedim);
        margin-bottom: 10px;
      }
      .CardItem {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        b {
          text-decoration: underline;
          font-size: 20px;
          margin-left: 5px;
        }
      }
      .ServiceSelect {
     
        margin: 0 0 10px 0;
        .newClientAbonement {
          margin: 0 0 10px 0;
          font-weight: bold;
        }
      }
      .saleSearch {
        margin: 0 0 10px 0;
        display: flex;
        flex-direction: row;
        flex: 1;
      }
      .CardItemSpace {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        b {
          padding: 15px 0 15px 0;
        }
      }
      .ControlRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
