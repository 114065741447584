.VisitsScreen {
  display: flex;
  flex-direction: column;
  flex: 1;

  .VisitsFilter {
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px 10px 0 10px;
    width: 400px;
    margin: 0 0 10px 0;
    .VisitsFilterRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0 10px 0;
      span {
        font-weight: bold;
      }
     
    }
  }
}
