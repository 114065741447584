.SearchClient {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
  }

  .SearchRow {
    display: flex;
    flex-direction: row;
    .cardRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .SearchInput  {
      margin: 10px 10px 0 0;
      input {
        font-size: 20px;
      }
    }
    .SearchInputId {
      width: 100px;
      margin: 10px 10px 0 0;
      input {
        font-size: 20px;
      }
    }
  }

  .FoundClientList{
    margin: 15px 0 0 0;
    height: 100%;
  }
  .SearchClientHistory{
    .LastSearch{
      font-weight: bold;
      margin: 5px;
    }
  }
  .ClientName{
    padding: 10px;
    &:hover{
      background: var(--blue);
      color: #fff;
      cursor: pointer;
    }
  }
}
