table.Table {
  width: 100%;
  background-color: #e6e6e6;
  thead tr {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
  th,
  td {
    text-align: left;
    font-size: 18px;
    padding: 5px;
    height: 50px;
    &:first-child {
      padding-left: 10px;
    }
    &:not(:last-child) {
      border-right-width: 1px;
      border-right-style: solid;
    }
    &:last-child {
      padding-right: 10px;
    }
  }
  th {
    border-color: #fff;
    padding: 12px 5px 10px;
    .HeaderWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter {
        height: 24px;
        fill: var(--aqua);
        margin-left: 7px;
        padding: 2px;
        border: 1px solid #fff;
        cursor: pointer;
        display: block;
      }
      .sorter {
        margin-left: 7px;
        height: 24px;
        fill: var(--aqua);
        padding: 2px;
        border: 1px solid #fff;
        cursor: pointer;
        display: block;
        &.up {
          transform: scaleY(-1);
        }
      }
      .sorter:not(.show) {
        fill: transparent;
      }
    }
  }
  tr:nth-child(odd) td {
    border-color: #e6e6e6;
    background-color: #f6f6f6;
  }
  tr:nth-child(even) td {
    border-color: #f6f6f6;
    background-color: #e6e6e6;
  }
  thead {
    border-radius: 7px;
    background-color: var(--blue);
    color: #fff;
  }
  .onRowClick {
    &:hover {
      cursor: pointer;
      color: #fff;
      td {
        border-color: var(--aqua);
        background-color: var(--aqua);
      }
    }
  }
}

.Pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .pageSwitch {
    margin-right: 5px;
    border: 2px solid #747474;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    min-width: 25px;
    font-size: 18px;
    color: #000;
    flex: 0 0 auto;
    cursor: pointer;
    &.empty {
      pointer-events: none;
      opacity: 0.75;
    }
    &.active,
    &:hover {
      background-color: #dddddd;
      border: 2px solid var(--blue);
    }
  }
}
