.Sales {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
    margin-bottom: 10px;
  }
  .SalesControlRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 0 10px 0;
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px;
    font-weight: bold;
  }
  .SalesHeader {
    display: flex;
    flex-direction: row;
    .SalesInfo {
      flex: 1;
      border: 1px solid var(--basedim);
      border-radius: 7px;
      padding: 10px;
      margin: 0 0 10px 5px;
      .SalesInfoItem{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--basedim);
        margin: 0 0 5px 0;
        font-weight: bold;
        .SalesInfoLabel{
          font-weight: 500;
          min-width: 150px;
        }
      }
    }
  }
  .SalesFilter {
    flex: 2;
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px;
    margin: 0 5px 10px 0;
    .SalesFilterRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      .SalesFilterItem {
        span {
          margin: 0 0 3px 0;
          font-weight: bold;
        }
      }
      .SalesFilterInput{
        margin: 0;
     
        width: 100%;
      }
    }
    .FilterControl {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.CancelSales {
  svg {
    fill: red;
    width: 25px;
    height: 25px;
    opacity: 0.4;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
