.GiftsScreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  .Header {
    font-weight: bold;
    border-bottom: 1px solid var(--basedim);
    margin-bottom: 10px;
  }
  .ControlRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Archive {
    svg {
      width: 25px;
      height: 25px;
      fill: var(--blue);
      &:hover {
        cursor: pointer;
        fill: var(--aqua);
      }
    }
  }

  .TypesTabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    .TypesTab {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center;
      padding: 5px 15px 5px 15px;
      width: 250px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #46494d;
    }
    @media only screen and (max-device-width: 640px) {
      .authTab {
        width: 100%;
      }
    }
    .active {
      border-bottom: 1px solid var(--basedim);
      color: var(--blue);
    }
  }
  .Card {
    width: 800px;
    border: 1px solid var(--basedim);
    border-radius: 7px;
    padding: 10px;
    margin: 0 0 50px 0;
    &:hover {
      border: 1px solid var(--blue);
    }
    .Title {
      font-weight: bold;
      border-bottom: 1px solid var(--basedim);
      margin-bottom: 10px;
    }
    .CardItem {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      b {
        text-decoration: underline;
        font-size: 20px;
        margin-left: 5px;
      }
    }
    .ServiceSelect {
   
      margin: 0 0 10px 0;
      .newClientAbonement {
        margin: 0 0 10px 0;
        font-weight: bold;
      }
    }
    .saleSearch {
      margin: 0 0 10px 0;
      display: flex;
      flex-direction: row;
      flex: 1;
    }
    .CardItemSpace {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      b {
        padding: 15px 0 15px 0;
      }
    }
    .ControlRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
